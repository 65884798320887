import * as React from "react"
import { Link } from "gatsby"

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import { linkResolver } from "../linkresolver"
import Layout from '@components/layout'
import WastewaterAudit from '@components/wastewater-audit'
import MustRead from '@components/must-read'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'

// markup
const NotFoundPage = ({ data }) => {
  return (
    <Layout pageTitle="404">
      <Container className="section">
        <Row>
          <Col>
            <h1>Page Not Found</h1>
            <p>
              It looks like nothing was found at this location. Maybe try one of the links below?
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Case Studies</h2>
            <ul>
              <li>
                <Link to="/case-studies/wastewater-approach-reduces-sewer-charges">Food manufacturer saves $240K a year</Link>
              </li>
              <li>
                <Link to="/case-studies/bulk-haulage-solution-saves-time-money">Municipality saves big on bulk waste haulage</Link>
              </li>
              <li>
                <Link to="/case-studies/wastewater-plant-pumping-emergency">All hands on deck in Halton</Link>
              </li>
              <li>
                <Link to="/case-studies/economic-benefit-biosolids-application">Biosolids vs. chemical fertilizer</Link>
              </li>
              <li>
                <Link to="/case-studies/lagoon-dewatering-approach-wins-award">Innovations in lagoon dewatering</Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Services</h2>
            <ul>
              <li>
                <Link to="/services/bypass">Bypass</Link>
              </li>
              <li>
                <Link to="/services/clean-out">Clean Out</Link>
              </li>
              <li>
                <Link to="/services/dewatering">Dewatering</Link>
              </li>
              <li>
                <Link to="/services/hydro-vac">Hydro-vac</Link>
              </li>
              <li>
                <Link to="/services/inspection">Inspection &amp; Repair</Link>
              </li>
              <li>
                <Link to="/services/land-application">Land Application</Link>
              </li>
              <li>
                <Link to="/services/storage">Storage</Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Sectors</h2>
            <ul>
              <li>
                <Link to="/sectors/#agricultural">Agricultural</Link>
              </li>
              <li>
                <Link to="/sectors/#construction">Construction</Link>
              </li>
              <li>
                <Link to="/sectors/food-and-beverage">Food and Beverage</Link>
              </li>
              <li>
                <Link to="/sectors/#municipal">Municipal</Link>
              </li>
              <li>
                <Link to="/sectors/#heavy-industry">Heavy Industry</Link>
              </li>
              <li>
                <Link to="/sectors/#pulp-and-paper">Pulp and Paper</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <MustRead />
    </Layout>
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage)
